<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="client.email"
              label="Email"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="client.name"
              label="Name"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="client.phone"
              label="Phone"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="client.website"
              label="website"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="client.address"
              label="Address"
              type="text"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="client.state"
              label="State"
              type="text"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="client.city"
              label="City"
              type="text"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="client.zip"
              label="Zip"
              type="text"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="client.country"
              label="Country"
              type="text"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="client.service"
              label="Service"
              type="text"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click.prevent="updateClient"
            >
              Save
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="cancel"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from '@axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      client: [],
    }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  methods: {
    updateClient() {
      this.client.company_name = this.client.name
      axios
        .put('/account/update', {
          client: this.client,
        })
        .then(response => {
          console.log(response.data)
          if (response.data.error) {
            this.$alertify.error(response.data.error)
          } else {
            this.$alertify.success('Client updated')
            this.$router.push('/client')
            localStorage.removeItem('clientEdit')
          }
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to update!')
          console.log('error :>> ', error.response)
          this.$alertify.error('unable to update')
        })
    },
    cancel() {
      this.$router.push('/client')
    },
  },
  mounted() {
    if (this.hasUserRole('Super Admin') || this.hasUserRole('Client Owner')) {
      this.client = JSON.parse(localStorage.getItem('clientEdit'))
    } else {
      this.loading = false
      this.$router.push({ name: 'misc-not-authorized' })
    }
  },
}
</script>
